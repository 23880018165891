@import "../../assets/variableStyles.scss";


/* Datepicker Custom styles */
.react-datepicker-component {
  .react-datepicker-input {
    input {
      background: #fff !important;
      padding-left: 38px !important;
      padding-right: 0 !important;
    }
    .button-wrapper {
      position: absolute !important;
      left: 0 !important;
      right: initial !important;
      i {
        color: #0775e2;
      }
    }
  }
  .react-datepicker-input.has-value {
    input {
      font-weight: 100 !important;
      color: #505c75 !important;
    }
  }
}
/* End of Datepicker Custom styles*/

.flight_search_box {
  input {
    border-color: #bcd5f5;
  }
  background: #fff;
  padding: 25px 20px 0;
  border-radius: 5px;
  margin-top: 10px;
  .flight-header {
    margin-bottom: 4px;
    label {
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      &:active {
        color: #1c2b39;
        font-weight: 700;
      }
    }
    .flight-class {
      label {
        font-size: 12px;
        margin-bottom: 7px;
      }
      .col {
        padding-left: 0;
        .custom-select {
          border: transparent;
          font-size: 12px;
          font-weight: 700;
          line-height: 19px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .flight-body {
    .custom-column {
      margin-bottom: 0.5rem;
      position: relative;
      label {
        font-size: 11px;
        font-weight: 700;
        color: #1c2b39;
        margin-bottom: 0;
      }
      .flight-search-btn {
        background-color: #e2076a;
        color: #fff;
        border-color: transparent;
        &:hover {
          background-color: #eb5196;
        }
      }
      .city-increment {
        position: absolute;
        right: -20px;
        top: 50%;
      }
    }
    .return-date-col {
      .react-datepicker-component {
        .react-datepicker-input.has-value {
          &::after {
            content: "\f00d";
            font-family: FontAwesome;
            right: 10px;
            position: absolute;
            top: 0px;
            border-left: 1px solid #bcd5f5;
            padding-left: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          &:hover {
            color: red !important;
          }
        }
      }
    }
  }
  .multi-city-wrapper {
    .city-count {
      width: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .place-box {
      width: 36% !important;
      padding-right: 10px;
      padding-left: 10px;
    }
    .departure-date-box {
      width: 23%;
    }
    .city-increment {
      margin-left: 10px !important;
      align-self: center;
    }
  }
}
#multiCityForm {
  display: none;
}
.plus-icon {
  font-size: 25px;
}
.paxpopup {
  width: 280px;
  .ant-popover-inner-content {
    padding: 0;
  }
  .pax-modal {
    margin-left: 115px;
    @include mobile{
      margin-left: 0px;
    }
    .pax-modal-wrapper {
      padding: 10px 20px;
      border: 1px solid grey;
     
      li {
        display: flex;
        list-style-type: none;
        border-bottom: 1px solid #dcdee3;
        margin-bottom: 2px;
        margin-top: 8px;
        padding-bottom: 8px;
        &:last-child {
          border-bottom-color: transparent;
        }
        .pax-label {
          flex-grow: 1;
          text-align: start;
          p {
            margin-bottom: -6px;
            font-size: 16px;
            font-weight: 400;
            color: #05004e;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            color: #969dac;
          }
        }
        .pax-count {
          flex-grow: 1;
          align-self: baseline;
          display: flex;
          justify-content: space-evenly;
          line-height: 27px;
          .fa {
            background: #fff;
            border: 1px solid #e8e8e8;
            padding: 0px;
            border-radius: 2px;

            color: #000;
            line-height: 1;
            vertical-align: sub;
            width: 25px;
            height: 25px;
            line-height: 25px;
            text-align: center;
          }
          .fa-minus {
            //border-top-left-radius: 4px;
            //border-bottom-left-radius: 4px;
            border-radius: 2px;
            font-size: 14px;
          }
          span {
            font-size: 16px;
          }
          .fa-plus {
            //border-top-right-radius: 4px;
            //border-bottom-right-radius: 4px;
            border-radius: 2px;
            font-size: 14px;
          }
        }
      }
      .pax-ready-btn {
        border-radius: 3px;
        background: #fff;
        padding: 6px;
        color: #0775e2;
        font-size: 11px;
        font-weight: 700;
        border: 2px solid #0775e2;
        margin-top: 20px;
        &:hover {
          background: #0775e2;
          color: #fff;
        }
      }
    }
  }
}
.primary-btn {
  background-color: #da251c !important;
  color: #fff !important;
  margin-right: 0 !important;
}
.from-to-inputs {
  &:hover{
    background-color:#E0F2FD;
  }
  .ant-row {
    flex-direction: column;
    align-items: flex-start;
    .ant-form-item-control {
      width: 100%;
    }
  }
}
.mr-none {
  margin-right: 0 !important;
}
.class-container {
  // display: flex;
  // justify-content: flex-end;
  // align-items: center;
  // padding: 8px 0;
  .class-type {
    font-size: 11px;
    margin-right: 7px;
  }
  .ant-select-selector {
    border: none !important;
    font-size: 12px;
    font-weight: 700;
  }
}

.hide-icon-heading-two {
  display: none;
}

.position-org{
  border: 2px solid transparent;
  visibility: visible;
  @include mobile{
    visibility: hidden;
  }
}
 